export default {
  title: "ADVANCED.benefitSectionTitleAdvanced",
  coverImage: require("../../images/new/Disinfection-service-whocanuseit.png"),
  items: [
    {
      image: require("../../images/checked.svg"),
      text: "ADVANCED.benefitSection1Advanced",
    },
    {
      image: require("../../images/checked.svg"),
      text: "ADVANCED.benefitSection2Advanced",
    },
    {
      image: require("../../images/checked.svg"),
      text: "ADVANCED.benefitSection3Advanced",
    },
    {
      image: require("../../images/checked.svg"),
      text: "ADVANCED.benefitSection4Advanced",
    },
    {
      image: require("../../images/checked.svg"),
      text: "ADVANCED.benefitSection5Advanced",
    },
    {
      image: require("../../images/checked.svg"),
      text: "ADVANCED.benefitSection6Advanced",
    },
    {
      image: require("../../images/checked.svg"),
      text: "ADVANCED.benefitSection7Advanced",
    },
    {
      image: require("../../images/checked.svg"),
      text: "ADVANCED.benefitSection8Advanced",
    },
  ],
};
