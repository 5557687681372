import React from "react";
import DisinfectionSlider from "../../DisinfectionSlider";

export default (props) => {
  const { commercial = false, vehicle = false } = props;
  return (
    <section style={{ padding: "50px 0" }}>
      <div className="container">
        <div className="row">
          <div className="col-md-12 justify-content-center mx-auto">
            <DisinfectionSlider commercial={commercial} vehicle={vehicle} />
          </div>
        </div>
      </div>
    </section>
  );
};
