import React from "react";
import SeoDetails from "./constants/SeoDetails";

export default () => {

  let data = SeoDetails["default"];

  return (
    <section>
      <div className="container">
        <p className="smallerSubtitle text-left my-5 bottom-seo">
          {data.title}
          <br />
          {data.body}
        </p>
      </div>
    </section>
  );
};
