import React from "react";

export default function VideoLoader(props) {
  const { source } = props;

  return (
    <video autoPlay loop muted>
      <source src={source} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
}
