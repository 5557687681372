import React from "react";
import FaqAccordian from "../../FaqAccordian";
import { useTranslation } from "gatsby-plugin-react-i18next";

export default (props) => {
  const {
    data,
    spec = false,
    noBackground = false,
    commercial = false,
    office = false,
    home = false,
  } = props;
  const { t } = useTranslation();

  const videoUrl = () => {
    if (data.video) {
      if (commercial) {
        return data.video.commercial;
      }
      if (office) {
        return data.video.office;
      }
      if (home) {
        return data.video.home;
      }
    }
    return null;
  };

  return (
    <section id="faq" style={{ padding: "50px 0px" }}>
      <div className={noBackground ? "" : "faq"}>
        <div className="container" style={{ marginTop: 50, paddingBottom: 50 }}>
          <div className="row justify-content-center">
            <div className="col-md-8 justify-content-center">
              {!!data.video && videoUrl() && (
                <div
                  className="mx-auto text-center mb-5"
                  style={{
                    position: "relative",
                    paddingBottom: "56.25%" /* 16:9 */,
                    paddingTop: 25,
                    height: 0,
                  }}
                >
                  <iframe
                    title={data.title}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                    src={!!data.video && videoUrl()}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              )}
              {!!data.title && (
                <h1
                  className="subheadline2 py-5 text-center"
                  style={{ marginTop: -40 }}
                >
                  {t(data.title)}
                </h1>
              )}
              <FaqAccordian
                spec={data.productSpecUrl}
                data={!!data.items && data.items}
              />
            </div>
            {/* {!!data.note && (
              <p className="smallerSubtitle mt-3" style={{ fontSize: 14 }}>
                {t(data.note)}
              </p>
            )} */}
          </div>
        </div>
      </div>
    </section>
  );
};
