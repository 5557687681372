import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

export default (props) => {
  const { data } = props;
  const { t } = useTranslation();

  return (
    <section id="why" style={{ padding: "0px 0" }}>
      <div className="container" style={{ marginTop: 100, paddingBottom: 50 }}>
        <div className="row">
          <div className="col-md-4">
            <img
              src={data.coverImage}
              style={{ width: "130%", marginLeft: -100 }}
            />
          </div>
          <div className="col-md-1" />
          <div className="col-md-7 d-flex flex-column pr-3">
            <h1 className="subheadline2">{t(data.title)}</h1>
            <div className="row pt-2">
              {data.items.map((item) => (
                <div
                  key={item.text}
                  className="col-md-12"
                  style={{ margin: "-10px 0 -10px 0" }}
                >
                  <div className="col d-flex flex-row my-3">
                    <div className="col-md-1 text-right descimage pr-0 py-2">
                      <img src={item.image} />
                    </div>
                    <div className="col-md-8 px-3 py-2">
                      <p className="subheadline5">{t(item.text)}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
