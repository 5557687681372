import React, { Fragment } from "react";

// Custom components
import ContactDetails from "./constants/ContactDetails";
import { useTranslation } from "gatsby-plugin-react-i18next";

export default (props) => {
  const { form, vehicles = false } = props;
  const { t } = useTranslation();

  let data=ContactDetails["default"];

  const ContactCard = (props) => {

    return (
      <div className="card-deck pt-5">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col">
                <div className="row mx-auto text-center">
                  <a
                    className="mx-auto my-3"
                    href={data.link}
                    style={{
                      color: "#3b3a3b",
                      fontSize: 16,
                      fontFamily: "lato",
                    }}
                  >
                    {t(data.text1)}
                    <br />
                    <img src={data.icon} style={{ width: 15, height: 15 }} />
                    <strong className="strong3">{t(data.text2)}</strong>
                  </a>
                </div>
                {!!data.text3 && !!data.text4 && (
                  <div className="row mx-auto text-center">
                    <a
                      className="mx-auto my-3"
                      href={data.link2}
                      style={{
                        color: "#3b3a3b",
                        fontSize: 16,
                        fontFamily: "lato",
                      }}
                    >
                      {t(data.text3)}
                      <br />
                      <img src={data.icon2} style={{ width: 15, height: 15 }} />
                      <strong className="strong3">{t(data.text4)}</strong>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const CallBackForm = form && form;
  const withBG = form ? "" : "faq";
  return (
    <section id="contact" className={withBG} style={{ padding: "50px 0px" }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 text-center">
            <h1 className="subheadline2 text-center">{t(data.title)}</h1>
            {form ? (
              <Fragment>
                <div className="row text-center">
                  <a
                    className="nav-link text-center mx-auto"
                    href={data.link}
                    style={{
                      color: "#3b3a3b",
                      fontSize: 12,
                      fontFamily: "lato",
                    }}
                  >
                    {t(data.text1)}
                    <br />
                    <img src={data.icon} style={{ width:18, height: 18, marginBottom:6 }} />
                    <strong className="strong3">{t(data.text2)}</strong>
                  </a>
                </div>
                {!!data.text3 && !!data.text4 && (
                  <div className="row text-center">
                    <a
                      className="nav-link text-center mx-auto"
                      href={data.link2}
                      style={{
                        color: "#3b3a3b",
                        fontSize: 12,
                        fontFamily: "lato",
                      }}
                    >
                      {t(data.text3)}
                      <br />
                      <img src={data.icon2} style={{ width: 18, height:18 ,  marginBottom:6 }} />
                      <strong className="strong3">{t(data.text4)}</strong>
                    </a>
                  </div>
                )}
              </Fragment>
            ) : (
              <ContactCard data={data} />
            )}
            {form && (
              <div className="mt-4 text-left">
                <CallBackForm vehicles />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
