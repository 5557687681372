import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "gatsby-plugin-react-i18next";

export default (props) => {
  const { data, spec } = props;
  const { t } = useTranslation();
  const [collapse, setCollapse] = useState(false);

  const handleClick = () => setCollapse(!collapse);
  const buttonIcon = collapse ? faAngleUp : faAngleDown;

  return (
    <div className="accordion" id="faqAccordian">
      {data &&
        data.map((item, key) => {
          const isVideo = item && !!item.video;
          const hasList = item && item.listGroup;
          return (
            <div key={`accordian-${key}`} className="card">
              <button
                id={`heading-${key}`}
                className="card-header btn btn-link subheadline4 btn-wrap-text text-left"
                type="button"
                data-toggle="collapse"
                data-target={`#collapse-${key}`}
                aria-expanded="false"
                aria-controls={`collapse-${key}`}
                onClick={handleClick}
              >
                <div className="row pl-2 pr-2">
                  <p className="m-0">{t(item.question)}</p>
                  <FontAwesomeIcon icon={buttonIcon} className="ml-auto" />
                </div>
              </button>
              <div
                id={`collapse-${key}`}
                className="collapse"
                aria-labelledby={`heading-${key}`}
                data-parent="#faqAccordian"
              >
                <div
                  className="card-body smallerSubtitle text-left"
                  style={{ color: "#929292", whiteSpace: "pre-line" }}
                >
                  {isVideo ? (
                    <iframe
                      width="560"
                      height="315"
                      src={item.video}
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  ) : (
                    t(item.answer)
                  )}
                  {hasList && (
                    <ul>
                      {item.listGroup.map((listItem) => (
                        <li key={listItem}>{t(listItem)}</li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      {!!spec && (
        <div className="card">
          <button
            id="headingEight"
            className="card-header btn btn-link collapsed subheadline4 btn-wrap-text text-left"
            type="button"
            data-toggle="collapse"
            data-target="#collapseEight"
            aria-expanded="false"
            aria-controls="collapseEight"
            onClick={handleClick}
          >
            <div className="row pl-2 pr-2">
              <p className="m-0">{t("FAQSECTION.faqProductSpecSheet")}</p>
              <FontAwesomeIcon icon={buttonIcon} className="ml-auto" />
            </div>
          </button>
          <div
            id="collapseEight"
            className="collapse"
            aria-labelledby="headingEight"
            data-parent="#faqAccordian"
          >
            <div
              className="card-body smallerSubtitle text-left"
              style={{ color: "#929292" }}
            >
              {t("FAQSECTION.faqProductSpecSheetDownload")}
              <br />
              <span>
                <a href={spec}>{t("FAQSECTION.faqProductSpecSheet")}</a>
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
