export default {
  title: "BENEFITSECTION.benefitSectionTitleCommercial",
  coverImage: require("../../images/Disinfection-service-whocanuseit.png"),
  items: [
    {
      image: require("../../images/checked.svg"),
      text: "BENEFITSECTION.benefitSection1Commercial",
    },
    {
      image: require("../../images/checked.svg"),
      text: "BENEFITSECTION.benefitSection2Commercial",
    },
    {
      image: require("../../images/checked.svg"),
      text: "BENEFITSECTION.benefitSection3Commercial",
    },
    {
      image: require("../../images/checked.svg"),
      text: "BENEFITSECTION.benefitSection4Commercial",
    },
    {
      image: require("../../images/checked.svg"),
      text: "BENEFITSECTION.benefitSection5Commercial",
    },
  ],
};
