const template = [
  {
    question: "ADVANCED.FAQquestion1Advanced",
    answer: "ADVANCED.FAQanswer1Advanced",
  },
  {
    question: "ADVANCED.FAQquestion2Advanced",
    answer: "ADVANCED.FAQanswer2Advanced",
  },
  {
    question: "ADVANCED.FAQquestion3Advanced",
    answer: "ADVANCED.FAQanswer3Advanced",
  },
  {
    question: "ADVANCED.FAQquestion4Advanced",
    answer: "ADVANCED.FAQanswer4Advanced",
  },
  {
    question: "ADVANCED.FAQquestion5Advanced",
    answer: "ADVANCED.FAQanswer5Advanced",
  },
  {
    question: "ADVANCED.FAQquestion6Advanced",
    answer: "ADVANCED.FAQanswer6Advanced",
  },
  {
    question: "ADVANCED.FAQquestion7Advanced",
    answer: "ADVANCED.FAQanswer7Advanced",
  },
  {
    question: "ADVANCED.FAQquestion8Advanced",
    answer: "ADVANCED.FAQanswer8Advanced",
    listGroup: [
      "ADVANCED.FAQanswer8_2Advanced",
      "ADVANCED.FAQanswer8_3Advanced",
      "ADVANCED.FAQanswer8_4Advanced",
      "ADVANCED.FAQanswer8_5Advanced",
      "ADVANCED.FAQanswer8_6Advanced",
      "ADVANCED.FAQanswer8_7Advanced",
      "ADVANCED.FAQanswer8_8Advanced",
    ],
  },
  {
    question: "ADVANCED.FAQquestion9Advanced",
    answer: "ADVANCED.FAQanswer9Advanced",
  },
  {
    question: "ADVANCED.FAQquestion10Advanced",
    answer: "ADVANCED.FAQanswer10Advanced",
  },
];

export default {
  title: "ADVANCED.FAQsectionTitleAdvanced",
  items: template,
  // productSpecUrl:
  //   "https://assets.servishero.com/Advanced_Disinfection_Product_Spec.pdf",
  note:
    "* Treated surface must not be compromised by touching or wiping to maintain protection",
};
