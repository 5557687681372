import React from "react";
import FeaturedInSlider from "../../FeaturedInSlider";
import { useTranslation } from "gatsby-plugin-react-i18next";

export default (props) => {
  const { t } = useTranslation();

  return (
    <div className="container my-5 text-center">
      <p className="subheadline4 mx-5">{t("ASFEATURED.asFeatured")}</p>
      <FeaturedInSlider />
    </div>
  );
};
