import React from "react";

export default (props) => {
  const { image, imageAlt, title } = props;

  return (
    <section style={{ backgroundColor: "#ffcc09" }}>
      <div className=" row py-3 mx-0 justify-content-center">
        <div>
          <img
            src={image}
            style={{ width: "auto", maxHeight: 50, height: 25 }}
            alt={imageAlt}
          />
        </div>
        <div className="align-self-center">
          <p className="subheadline7 my-0 ml-4">{title}</p>
        </div>
      </div>
    </section>
  );
};
