import React, { Fragment, useState } from "react";
// External libraries
import moment from "moment";
import ReactGA from "react-ga";
import $ from "jquery";
import { useTranslation } from "gatsby-plugin-react-i18next";

ReactGA.initialize("AW-732718445");

export default (props) => {
  const { t } = useTranslation();
  const { top = false, leads } = props;
  const [email, setEmail] = useState(null);
  const [name, setName] = useState(null);
  const [contactNumber, setContactNumber] = useState(null);
  const [problem, setProblem] = useState(null);
  const [area, setArea] = useState(null);
  const [location, setLocation] = useState(null);

  function resetForm() {
    setEmail(null);
    setName(null);
    setContactNumber(null);
    setProblem(null);
    setArea(null);
    setLocation(null);

  }

  function handleSubmit(e) {
    e.preventDefault();
    const now = new Date();

    const formValues = {
      timestamp: moment(now).format("DD-MM-YYYY, h:mm a"),
      name,
      "contact-number": contactNumber,
      email,
      problem,
      area,
      location,
      leads: leads
    };

    const callback = () => {
      console.log("event successful");
      $("#thankYouModal").modal("show");
      window.scrollTo(0, 0);
    };

    $.ajax({
      url:
        "https://script.google.com/macros/s/AKfycbzEa4A2FzXfujIkBtHYgDy4mRTcjoIRRAKNy2O6OWvmdYwF85k/exec",
      method: "GET",
      dataType: "json",
      data: formValues,
      success() {
        // do something
        console.log("submit successful");
        resetForm();
        document.getElementById("interest-form").reset();
        $("#thankYouModal").modal("show");
        ReactGA.ga("event", "conversion", {
          send_to: "AW-732718445/Ztx2CJLgk8cBEO3Ksd0C",
          event_callback: callback(),
        });
      },
    });
  }

  return (
    <Fragment>
      <form id="interest-form" className="" onSubmit={handleSubmit}>
        <div className="card mb-3 shadow rounded">
          <div className="card-body p-4">
            <h1 className="subheadline">{t("CALLBACKFORM.formTitle")}</h1>
            <input type="hidden" id="timestamp" name="timestamp" value="" />

            <div className="form-group">
              {/* <label htmlFor="name">Name *</label> */}
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                placeholder={t("CALLBACKFORM.name")}
                required
                onChange={(e) => setName(e.currentTarget.value)}
              />
            </div>
            <div className="form-group">
              {/* <label htmlFor="contact-number">Contact Number *</label> */}
              <input
                type="tel"
                className="form-control"
                id="contact-number"
                name="contact-number"
                placeholder={t("CALLBACKFORM.contactNumber")}
                required
                onChange={(e) => setContactNumber(e.currentTarget.value)}
              />
            </div>
            <div className="form-group">
              {/* <label htmlFor="email">Email *</label> */}
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                placeholder={t("CALLBACKFORM.email")}
                onChange={(e) => setEmail(e.currentTarget.value)}
              />
            </div>
            <div className="form-group">
              {/* <label htmlFor="address">Address</label> */}
              <input
                className="form-control"
                rows="3"
                id="location"
                name="location"
                placeholder={t("CALLBACKFORM.location")}
                onChange={(e) => setLocation(e.currentTarget.value)}
              />
            </div>
            <div className="form-group">
              {/* <label htmlFor="address">Address</label> */}
              <textarea
                className="form-control"
                rows="3"
                id="problem"
                name="problem"
                placeholder={t("CALLBACKFORM.problem")}
                onChange={(e) => setProblem(e.currentTarget.value)}
              />
            </div>
            <div className="form-group">
              <label for="area">{t("CALLBACKFORM.area")}</label>
              <div className="form-check">
                <input className="form-check-input" type="radio" id="area" name="area" value="<100 m" required
                  onChange={(e) => setArea(e.currentTarget.value)} />
                  <label for="<100m">kurang dari 100 m<sup>2</sup></label><br/>
                <input className="form-check-input" type="radio" id="area" name="area" value="100 - 500 m"
                  onChange={(e) => setArea(e.currentTarget.value)}/>
                  <label for="100-500m">100 - 500 m<sup>2</sup></label><br/>
                <input className="form-check-input" type="radio" id="area" name="area" value="500 - 1000 m"
                  onChange={(e) => setArea(e.currentTarget.value)}/>
                  <label for="500-1000">500 - 1000 m<sup>2</sup></label><br/>
                <input className="form-check-input" type="radio" id="area" name="area" value="> 1000 m"
                  onChange={(e) => setArea(e.currentTarget.value)}/>
                  <label for=">1000m"> lebih dari 1000 m<sup>2</sup></label>
              </div>
            </div>
            <div className="text-center">
              <button
                type="submit"
                id="submit-form"
                className="CTA CTA-secondary btn btn-primary px-5 mx-auto"
              >
                {t("CALLBACKFORM.submitBtn")}
              </button>
            </div>
          </div>
        </div>
      </form>
    </Fragment>
  );
};
