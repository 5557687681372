import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";

export default(props) => {
  const { t } = useTranslation();
  return (
    <section style={{ padding: "50px 0 0 0" }}>
      <div className="container my-5 text-center">
        <p className="subheadline4 mx-5 mb-5">{t("CLIENTSECTION.clientSectionTitle")}</p>
        <div className="row justify-content-between">
          <div className="col-md-2 text-center subtitle">
            <div>
              <img
                src={require("../../../images/new/protected-car.png")}
                className="mb-2"
                style={{ maxWidth: 100 }}
              />
            </div>
            {t("CLIENTSECTION.clientSectionClient1")}
          </div>
          <div className="col-md-2 text-center subtitle">
            <div>
              <img
                src={require("../../../images/new/banks.png")}
                className="mb-2"
                style={{ maxWidth: 100 }}
              />
            </div>
            {t("CLIENTSECTION.clientSectionClient2")}
          </div>
          <div className="col-md-2 text-center subtitle">
            <div>
              <img
                src={require("../../../images/new/developer-construction.png")}
                className="mb-2"
                style={{ maxWidth: 100 }}
              />
            </div>
            {t("CLIENTSECTION.clientSectionClient3")}
          </div>
          <div className="col-md-2 text-center subtitle">
            <div>
              <img
                src={require("../../../images/new/hotels.png")}
                className="mb-2"
                style={{ maxWidth: 100 }}
              />
            </div>
            {t("CLIENTSECTION.clientSectionClient4")}
          </div>
          <div className="col-md-2 text-center subtitle">
            <div>
              <img
                src={require("../../../images/new/hospitals.png")}
                className="mb-2"
                style={{ maxWidth: 100 }}
              />
            </div>
            {t("CLIENTSECTION.clientSectionClient6")}
          </div>
          <div className="col-md-2 text-center subtitle">
            <div>
              <img
                src={require("../../../images/new/offices.png")}
                className="mb-2"
                style={{ maxWidth: 100 }}
              />
            </div>
            {t("CLIENTSECTION.clientSectionClient5")}
          </div>
        </div>
      </div>
    </section>
  );
};
