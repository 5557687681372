import React from "react";
import VideoLoader from "../../VideoLoader";

export default (props) => {
  const {
    body,
    bodyTagline,
    buttonText,
    form,
    title,
    topSubtitle,
    vehicles = false,
    video,
  } = props;
  const Form = form;

  const colNum = form ? 7 : 9;
  return (
    <section id="intro" className="section-with-video py-50">
      {!!video && (
        <>
          <div className="overlay" />
          <div className="video">
            <VideoLoader source={video} />
          </div>
        </>
      )}
      <div className="container">
        <div className="row hdiimagebg">
          <div className={`col-md-${colNum} d-flex flex-column pr-3 px-5`}>
            <div className="row m-0" style={{ color: "white" }}>
              <h6
                className="subheadline m-0 pt-1"
                style={{ color: "white", verticalAlign: "middle" }}
              >
                {topSubtitle}
              </h6>
            </div>
            <h1
              className="headline"
              style={{ color: "white", whiteSpace: "pre-line" }}
            >
              {title}
            </h1>
            <div>
              <a
                className="CTA CTA-secondary btn btn-primary btn-lg my-3 shadow-none"
                href="#contact"
              >
                {buttonText}
              </a>
            </div>
            <div className="subtitle my-4 text-left mb-5 mb-sm-0">
              <p
                className="subtitle my-4 text-left"
                style={{ color: "white", whiteSpace: "pre-line" }}
              >
                {body}
                {!!bodyTagline && (
                  <>
                    <br />
                    <br />
                    {bodyTagline}
                  </>
                )}
              </p>
            </div>
          </div>
          {form && (
            <div className="col-md-5">
              {form && <Form vehicles={vehicles} />}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
