import { ProfessionalDisinfectionProductSpecSheet } from "../DocumentUrl";

const template1 = [
  {
    question: "COMMERCIAL.question1Commercial",
    answer: "COMMERCIAL.answer1Commercial",
  },
  {
    question: "COMMERCIAL.question2Commercial",
    answer: "COMMERCIAL.answer2Commercial",
  },
  {
    question: "COMMERCIAL.question3Commercial",
    answer: "COMMERCIAL.answer3Commercial",
  },
  {
    question: "COMMERCIAL.question4Commercial",
    answer: "COMMERCIAL.answer4Commercial",
  },
  {
    question: "COMMERCIAL.question5Commercial",
    answer: "COMMERCIAL.answer5Commercial",
  },
  {
    question: "COMMERCIAL.question6Commercial",
    answer: "COMMERCIAL.answer6Commercial",
  },
  {
    question: "COMMERCIAL.question7Commercial",
    answer: "COMMERCIAL.answer7Commercial",
  },
  {
    question: "COMMERCIAL.question8Commercial",
    answer: "COMMERCIAL.answer8Commercial",
  },
  {
    question: "COMMERCIAL.question9Commercial",
    answer: "COMMERCIAL.answer9Commercial",
  },
  {
    question: "COMMERCIAL.question10Commercial",
    answer: "COMMERCIAL.answer10Commercial",
  },
  {
    question: "COMMERCIAL.question11Commercial",
    answer: "COMMERCIAL.answer11Commercial",
  },
  {
    question: "COMMERCIAL.question12Commercial",
    answer: "COMMERCIAL.answer12Commercial",
  },
];

export default {
  title: "COMMERCIAL.faqSectionTitleCommercial",
  items: template1,
  productSpecUrl: ProfessionalDisinfectionProductSpecSheet,
  note: "COMMERCIAL.faqSectionNoteCommercial",
};
